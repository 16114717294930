import React from 'react';
import { authService } from '../../services/auth-service';
import qs from 'qs';

class Login extends React.PureComponent {
  componentDidMount() {
    const queryString = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    authService.login({
      state: authService.encodeState(queryString),
    });
  }

  render() {
    return null;
  }
}

export default Login;
